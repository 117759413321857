<template lang="pug">
	.page-wrapper(:class="pageClass", v-if="user")
		notifications(group="default")
		#sidebar
			.logo
				img(alt="logo", :src="logoPath", :class="{'cool-spin-hover': envName === 'ovalio-media'}")
			SideMenu
		.wrap
			header
				a.nav-btn(href="javascript:void(0);", @click="toggleSidebar")
					i.la.la-bars.la-lg
				.user-info
					router-link(:to="{ name:'profile' }")
						span {{ user.name }}
					bold.ml-1(v-if="user.logged_as") as
						.ml-1.badge.badge-pill.badge-info {{ user.logged_as }}
					a.btn-logout(href="javascript:void(0);", v-b-tooltip.hover, title="Logout", @click="logout()")
						i.la.la-sign-out
			#content
				router-view
		vue-progress-bar
</template>
<script>
import SideMenu from "../components/SideMenu";
import Vue from "vue";

export default {
	name: 'Layout',
	components: {SideMenu},
	computed: {
		pageClass() {
			return (this.toggledSidebar ? 'sidebar-toggled' : 'sidebar-default') + ' theme-' + this.envName;
		},
		user(){
			return this.$store.state.user
		},
		toggledSidebar(){
			return this.$store.state.toggledSidebar
		},
		logoPath(){
			if (this.envName === 'thing-or-two') {
				return require(`../assets/${this.envName}-logo-white.png`);
			}
			return require(`../assets/${this.envName}-logo.png`);
		}
	},
	methods: {
		logout(){
			this.$store.dispatch('setUser', null);
			this.$router.push({name: 'login'});
		},
		toggleSidebar(){
			this.$store.commit('toggle', 'toggledSidebar');
		},
	},
	data(){
		return {
			envName: Vue.envName || 'ovalio-media',
		};
	},
	mounted(){
		//  [App.vue specific] When App.vue is finish loading finish the progress bar
		this.$Progress.finish()
	},
	created(){
		// let user = localStorage.getItem('user');
		// let toggleSidebar = this.$store.state.toggledSidebar;
		//  [App.vue specific] When App.vue is first loaded start the progress bar
		this.$Progress.start();
		//  hook the progress bar to start before we move router-view
		this.$router.beforeEach((to, from, next) => {
			//  does the page we want to go to have a meta.progress object
			if (to.meta.progress !== undefined) {
				let meta = to.meta.progress;
				// parse meta tags
				this.$Progress.parseMeta(meta)
			}
			//  start the progress bar
			this.$Progress.start();
			//  continue to next page
			next()
		});
		//  hook the progress bar to finish after we've finished moving router-view
		this.$router.afterEach((/*to, from*/) => {
			//  finish the progress bar
			this.$Progress.finish();
		})
	}
}
</script>
