<script>
export default {
  name: 'ValidatedInput',
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    isValid: {
      type: Boolean,
      required: true,
    },
    errorMessage: {
      type: String,
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
  methods: {
    change(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>

<template lang="pug">
.form-group
  label(v-if="label") {{ label }}
  input.form-control(
    :value="value",
    :placeholder="placeholder",
    :class="{ 'invalid-input': !isValid }"
    @input="change",
  )
  p.error-message(v-if="!isValid && errorMessage") {{ errorMessage }}
</template>

<style lang="scss">
.invalid-input {
  box-shadow: 0 0 0 1px #cc0404ba;
}
.error-message {
  color: #cc0404ba;
}
</style>