<template lang="pug">
	div.page-login

		.widget
			.widget-body
				.logo
					img(alt="Logo", :src="logoPath", style="max-width: 100%")
				h1 Partners
				form(@submit.prevent="submit")
					.form-group
						input.form-control(type="email", v-model="email", placeholder="Email")
					.form-group
						input.form-control(type="password", v-model="password", placeholder="Password")
					.alert.alert-danger(v-if="errorText") {{ errorText }}
					.form-group.text-right
						button.btn.btn-sm.btn-primary(type="submit", :disabled="busy")
							| Login
							=' '
							.la(:class="{'la-arrow-right':!busy,'la-spinner la-spin':busy}")
</template>
<style lang="scss">
	.page-login {
		padding-top: 20vh;
		text-align: center;

		.widget {
			max-width: 460px;
			width: 100%;
			margin: auto;

			h1 {
				font-size: 30px;
				font-weight: 300;
				margin: 0 0 15px 0;
			}
		}
	}

</style>
<script>
	import Vue from 'vue';

	export default {
		data(){
			return {
				errorText: '',
				busy: false,
				email: '',
				password: '',
				envName: Vue.envName
			};
		},
		computed: {
			logoPath(){
				return require(`../assets/${this.envName}-logo.png`);
			},
			// fallbackLogo() {
			// 	return 'this.src="/assets/'+this.envName+'-logo.png";this.onerror=""';
			// }
		},
		methods: {
			submit(){
				if (this.busy) {
					return;
				}
				let data = {
					email: this.email,
					password: this.password
				};
				this.errorText = '';
				this.busy = true;
				//'auth/login'
				let loginUrl = process.env.VUE_APP_API_BASE_URL + 'api/auth/login';
				this.$http.post(loginUrl, data).then(response => {
					Vue.http.headers.common['Authorization'] = 'Bearer ' + response.body.data.token;
					this.$store.dispatch('setUser', {user: response.body.data.user, token: response.body.data.token});
					this.$router.push('/app')
				}, response => {
					this.busy = false;
					// error callback
					let errdesc = '';
					if (response.body && response.body.err) {
						errdesc = response.body.errdesc;
					} else {
						errdesc = 'There has been an error';
					}
					this.errorText = errdesc;
				});
			}
		}
	}
</script>
