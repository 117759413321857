<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="true")
		.widget(v-if="USER")
			.widget-header
				h1.title Offers
			.widget-body
				form.form.form-inline.ov-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
					a.toggle-filters(href="javascript: void(0);", @click="toggleFilters()")
						span Filters
						i.la.la-chevron-up
					.form-row-main
						div
							.form-group
								label Search
								input.form-control(type="text", placeholder="Keyword...", v-model="filters.keyword")
							.form-group
								label Status
								select.form-control(v-model="filters.status")
									option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
							.form-group
								label Countries
								input.form-control(type="text", v-model="filters.countries", placeholder="US, GB...")
							.form-group
								label OS
								select.form-control(v-model="filters.platform")
									option(v-for="o in platformOptions", :value="o.v") {{ o.t }}
							.form-group.no-label
								button.btn.btn-primary(type="submit") Go
				p.clearfix &nbsp;

				paginate(:paginator="paginate", @update-page-size="loadData")

				.ov-table-wrapper
					.tabs.table-tabs
						ul.nav.nav-tabs
							li.nav-item
								a.nav-link(:class="{active:selectedTab==='search'}", @click="selectedTab='search'") Search ({{ paginate.total }})
							li.nav-item
								a.nav-link(:class="{active:selectedTab==='bucket'}", @click="selectedTab='bucket'") Selected ({{bucket.length}})
					table.table.table-bordered.table-offer-list
						thead
							tr
								th
									span(v-if="selectedTab==='search'")
										a(href="javascript:void(0);", @click="selectAll()")
											i.la.la-check-square-o
										=' | '
									span
										a(href="javascript:void(0);", @click="clearBucket()")
											i.la.la-square-o
								th ID
								th &nbsp;
								th Name
								//th(v-if="USER.permissions['advertisers::VIEW']") Advertiser
								th Payout
								th Model
								th Payable Event
								th Geo
								th OS
								th Caps
								th(v-if="USER.permissions['offers::CLICK_CAP']") ClickCap
								th WL/BL
								th Status
								th(v-if="USER.permissions['offers::REDIRECTS']") Redirects
						tbody
							tr(v-if="records.length === 0")
								td(:colspan="numColumns") No matching records were found
							tr(v-for="r in (selectedTab==='bucket'?bucket:records)", v-bind:key="r.id", :class="{'row-selected': bucketHash[r.id]}")
								td.col-select(:class="{'bucket-row':bucketHash[r.id]}")
									a(href="javascript:void(0)", @click="toggleBucket(r)")
										i.la(:class="{'la-check-square':bucketHash[r.id], 'la-stop':!bucketHash[r.id]}")
								td
									router-link(:to="{name:'offer-view', params: {id:r.id}}") {{ r.id }}
								td
									div.app-icon-container
										img(v-if="r.icon", :src="r.icon")
								td {{ r.name }}
								//td(v-if="USER.permissions['advertisers::VIEW']") [{{ r.advertiser_id }}] {{ r.advertiser_name }}
								td {{ r.payout }}
								td {{ r.payout_type }}
								td {{ r.payable_event_name || '-' }}
								td {{ r.country }}
								td {{ r.platform }}
								td {{ r.daily_cap }}
								td(v-if="USER.permissions['offers::CLICK_CAP']") {{ r.daily_click_cap }}
								td &nbsp;
								td {{ r.status }}
								td(v-if="USER.permissions['offers::REDIRECTS']")
									span(v-if="r.scan_status === 1") {{ r.scan_nondirect_jumps }}
</template>
<script>
import Vue from 'vue';
export default {
	name: 'OfferList',
	components: {
		// OfferScanModal,
		// OfferWLBLModal,
		// QuickConnectOffersModal,
		// CodeViewModal,
		// OfferExportModal
	},
	computed: {
		USER(){
			return this.$store.state.user;
		},
		numColumns(){
			return this.$store.state.user.permissions['offers::REDIRECTS'] ? 13 : 12;
		}
	},
	data(){
		return {
			busy: false,
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			showAdvancedFilters: false,
			selectedTab: 'search',
			filters: {
				keyword: '',
				countries: '',
				platform: 'all',
				status: 'all',
			},
			bucket: [],
			bucketHash: {},
			records: [],
			platformOptions: [
				{v: 'all', t: 'All'},
				{v: 'android', t: 'Android'},
				{v: 'ios', t: 'iOS'},
				{v: 'mobile_web', t: 'MobileWeb'},
				{v: 'desktop', t: 'Desktop'},
				{v: 'empty', t: 'Empty'},
			],
			statusOptions: [
				{v: 'all', t: 'All'},
				{v: 'live', t: 'Live'},
				{v: 'paused', t: 'Paused'},
				{v: 'ended', t: 'Ended'}
			],
			payoutModelOptions: Vue.ovData.general.payoutTypeOptions.map(o => {
				return {v: o.v, t: o.v}
			}),
			sortBy: {
				field: 'id',
				direction: 'desc',
				sort: null
			},
			filtersExpanded: false
		};
	},
	methods: {
		toggleBucket(r){
			if (this.bucketHash[r.id]) {
				Vue.set(this.bucketHash, r.id, false);
				for (let i = 0; i < this.bucket.length; i++) {
					if (this.bucket[i].id === r.id) {
						this.bucket.splice(i, 1);
						return;
					}
				}
			} else {
				Vue.set(this.bucketHash, r.id, true);
				this.bucket.push(r);
			}
		},
		clearBucket(){
			this.bucket = [];
			this.bucketHash = {};
		},
		selectAll(){
			this.records.forEach(r => {
				if (!this.bucketHash[r.id]) {
					Vue.set(this.bucketHash, r.id, true);
					this.bucket.push(r);
				}
			});
		},
		async loadData(){
			this.busy = true;
			let params = {
				page: this.paginate.page,
				page_size: this.paginate.limit,
				keyword: this.filters.keyword,
				platform: this.filters.platform,
				countries: this.filters.countries,
				status: this.filters.status,
				// has_blacklist: this.filters.has_blacklist ? 1 : 0,
				// has_whitelist: this.filters.has_whitelist ? 1 : 0,
				// show_hidden: this.filters.show_hidden ? 1 : 0,
				// smartlink: this.filters.smartlink ? 1 : 0,
				// countries: this.filters.countries,
				// status: this.filters.status,
				// validation_status: this.filters.validation_status,
				// platform: this.filters.platform,
				// payout_model: this.filters.payout_model,
				sort_col: this.sortBy.field,
				sort_dir: this.sortBy.direction,
				// max_redirects: this.filters.max_redirects,
				// offer_ids: this.filters.offer_ids,
				// offer_type: this.filters.offer_type,
				// complex_id: this.filters.complex_id,
				// mmp: this.filters.mmp
			};
			try {
				let userType = this.USER.type;
				let path = userType === 'advertiser' ? 'advertisers/offer/getList' : 'publishers/offer/getList';
				let resp = await this.$ovReq.get(path, {params});
				this.records = resp.records;
				// this.records.forEach(r => {
					// TODO - not an array - already a string
					// r.country = r.country.join(', ');
				// });
				this.paginate.total = resp.total;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e) {
				console.error(e);
			}
			this.busy = false;
		}
	},
	mounted(){
		this.loadData();
	},
	created(){
	}
}
</script>
