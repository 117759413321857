<template>
	<div id="app">
		<router-view/>
	</div>
</template>
<style lang="scss">
</style>
<script>
	export default {
		computed: {
			USER(){
				return this.$store.state.user;
			},
		},
		methods: {
		},
		mounted(){
		}
	}
</script>
