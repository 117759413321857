
export default {

	install(Vue/*, options*/){

		let api = {
			common: {
				getSelectedFromString: function (options, str){
					let selected = [];
					if (str) {
						str += '';
						let itemList = str.split(',').map(r => +r).filter(r => r);
						options.forEach(o => {
							if (itemList.indexOf(o.v) > -1) {
								selected.push(o);
							}
						});
					}
					return selected;
				}
			},

			general: {

				rejectReasons: [
					{v:'ctit_anomalies'},
					{v:'store_install_validation'},
					{v:'site_blacklist'},
					{v:'install_validation_rules'},
					{v:'bots'},
					{v:'click_flood'},
					{v:'behavioral_anomalies'},
					{v:'install_hijacking'},
					{v:'validation_bots'},
					{v:'validation_hijacking'},
				],

				platformOptions: [
					{v:'android', t:'android'},
					{v:'ios', t:'ios'},
					{v:'web', t:'web'},
				],
				currencyOptions: [
					{v: 'USD', t:'USD'},
					{v: '%', t:'%'},
				],

				timezoneOptions: [
					{v: -8, t: '-08:00'},
					{v: -7, t: '-07:00'},
					{v: -6, t: '-06:00'},
					{v: -5, t: '-05:00'},
					{v: -4, t: '-04:00'},
					{v: -3, t: '-03:00'},
					{v: -2, t: '-02:00'},
					{v: -1, t: '-01:00'},
					{v: 0, t: '+00:00 UTC'},
					{v: 1, t: '+01:00'},
					{v: 2, t: '+02:00'},
					{v: 3, t: '+03:00'},
					{v: 4, t: '+04:00'},
					{v: 5, t: '+05:00'},
					{v: 6, t: '+06:00'},
					{v: 7, t: '+07:00'},
					{v: 8, t: '+08:00'},
				],
				integrationTypeOptions: null,

				payoutTypeOptions: [{v: 'CPA'}, {v: 'CPI'}, {v: 'CPR'}, {v: 'CPC'}, {v: 'CPM'}, {v: 'CPS'}]
			},

			offer: {
				async get(id, userType){
					let path = userType === 'advertiser' ? 'advertisers/offer/get/' : 'publishers/offer/get/'
					let resp = await Vue.ovReq.get(path + id);
					return resp.entity;
				},
			},

			user: {
				_booleans: [
					'status', 'fun_mode',
				],
				_options: [],
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.ovReq.get('user/get/' + id);
						resp.entity.status = !!resp.entity.status;
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getPermissionGroups(){
					let resp = await Vue.ovReq.get('user/getPermissionGroups');
					return resp.permissions;
				},
				async getPermissionStructure(){
					let resp = await Vue.ovReq.get('user/getPermissionStructure');
					return resp.structure;
				},
			},

		};

		Vue.ovData = api;
		Vue.prototype.$ovData = api;
	}

};
