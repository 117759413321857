export default {

	install(Vue/*, options*/){

		let api = {
			get(url, config){
				return new Promise((resolve, reject) => {
					Vue.http.get(url, config)
						.then(
							resp => {
								if (resp.body.err === 0) {
									resolve(resp.body.data);
								} else {
									Vue.ovNotify.error(resp.body.errdesc);
									reject(resp.body);
								}
							},
							resp => {
								Vue.ovNotify.error();

								reject(resp);
							}
						);
				});

			},
			post(url, data, config){
				return new Promise((resolve, reject) => {
					Vue.http.post(url, data, config)
						.then(
							resp => {
								if (resp.body.err === 0) {
									resolve(resp.body.data);
								} else {
									Vue.ovNotify.error(resp.body.errdesc);
									reject(resp.body);
								}
							},
							resp => {
								Vue.ovNotify.error();
								reject(resp);
							}
						);
				});

			}
		};

		Vue.ovReq = api;
		Vue.prototype.$ovReq = api;
	}

};
