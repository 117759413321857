<template lang="pug">
	.paginate-wrapper
		//div First page: {{ firstPage }}
		//div Last page: {{ lastPage }}
		//div numGap: {{ numGap }}
		div.info
			span.info-text Showing {{ offset + 1 }} - {{ lastRecord }} of <strong>{{ paginator.total }}</strong>
		div.paginate-side
			nav
				ul.pagination
					li.page-item(:class="{'disabled':paginator.page==1}")
						a.page-link(@click="prev()") &laquo;
					li.page-item(:class="{'active':paginator.page==1}")
						a.page-link(@click="setPage(1)") 1
					li.page-item.disabled(v-if="firstPage>2")
						a.page-link ...
					
					li.page-item(:class="{'active':paginator.page==n+firstPage-1}", v-for="n in 3", :key="n", v-if="n <= lastPage")
						a.page-link(@click="setPage(n-1+firstPage)") {{ n-1+firstPage }}
					li.page-item.disabled(v-if="firstPage+lastPage < paginator.numPages")
						a.page-link ...
					li.page-item(v-if="paginator.numPages>1", :class="{'active':paginator.page==paginator.numPages}")
						a.page-link(@click="setPage(paginator.numPages)") {{ paginator.numPages }}
					li.page-item(:class="{'disabled':paginator.page==paginator.numPages}")
						a.page-link(@click="next()") &raquo;
			div.page-size
				select.form-control(v-model.number="paginator.limit", @change="updatePageSize()")
					option(v-for="o in pageSizeOptions", :value="o") {{ o }}


</template>
<script>
	export default {
		name: 'paginate',
		props: {
			paginator: {
				type: Object,
				required: true,
				default() {
					return {
						numPages: 0,
						total: 0,
						page: 1,
						limit: 30,
						onPageChange: () => {
							this.loadData();
						}
					};
				}
			}
		},
		data(){
			return {
				pageSizeOptions: [
					10, 20, 30, 40, 50, 100, 200, 500
				]
			}
		},
		computed: {
			offset(){
				return (this.paginator.page - 1) * this.paginator.limit;
			},
			lastRecord(){
				return Math.min(this.paginator.total, this.offset+this.paginator.limit)
			},
			firstPage(){
				return Math.max(this.paginator.page - 1, 2);
			},

			lastPage(){
				return Math.min(3, this.paginator.numPages - this.firstPage);
			},
			numGap(){
				let fp = Math.max(this.paginator.page - 1, 2);
				return Math.min(3, this.paginator.numPages - fp) - fp;
			}
		},
		methods: {
			updatePageSize(){
				this.$emit('update-page-size');
			},
			setPage(p){
				this.paginator.page = p;
				// this.paginator.page = p;
				if (this.paginator.onPageChange) {
					this.paginator.onPageChange();
				}
				// this.updateRecords();
			},
			next(){
				this.setPage(this.paginator.page + 1);
			},

			prev(){
				this.setPage(this.paginator.page - 1);
			}
		},

	}
</script>
